.tel-input {
  position: relative;
  width: 100%;

  .flag {
    width: 16px;
    height: 11px;
    background-image: url("../../public/flags.png");
  }

  .ad,
  .AD {
    background-position: -16px 0;
  }

  .ae,
  .AE {
    background-position: -32px 0;
  }

  .af,
  .AF {
    background-position: -48px 0;
  }

  .ag,
  .AG {
    background-position: -64px 0;
  }

  .ai,
  .AI {
    background-position: -80px 0;
  }

  .al,
  .AL {
    background-position: -96px 0;
  }

  .am,
  .AM {
    background-position: -112px 0;
  }

  .ao,
  .AO {
    background-position: -128px 0;
  }

  .ar,
  .AR {
    background-position: -144px 0;
  }

  .as,
  .AS {
    background-position: -160px 0;
  }

  .at,
  .AT {
    background-position: -176px 0;
  }

  .au,
  .AU {
    background-position: -192px 0;
  }

  .aw,
  .AW {
    background-position: -208px 0;
  }

  .az,
  .AZ {
    background-position: -224px 0;
  }

  .ba,
  .BA {
    background-position: -240px 0;
  }

  .bb,
  .BB {
    background-position: 0 -11px;
  }

  .bd,
  .BD {
    background-position: -16px -11px;
  }

  .be,
  .BE {
    background-position: -32px -11px;
  }

  .bf,
  .BF {
    background-position: -48px -11px;
  }

  .bg,
  .BG {
    background-position: -64px -11px;
  }

  .bh,
  .BH {
    background-position: -80px -11px;
  }

  .bi,
  .BI {
    background-position: -96px -11px;
  }

  .bj,
  .BJ {
    background-position: -112px -11px;
  }

  .bm,
  .BM {
    background-position: -128px -11px;
  }

  .bn,
  .BN {
    background-position: -144px -11px;
  }

  .bo,
  .BO {
    background-position: -160px -11px;
  }

  .br,
  .BR {
    background-position: -176px -11px;
  }

  .bs,
  .BS {
    background-position: -192px -11px;
  }

  .bt,
  .BT {
    background-position: -208px -11px;
  }

  .bw,
  .BW {
    background-position: -224px -11px;
  }

  .by,
  .BY {
    background-position: -240px -11px;
  }

  .bz,
  .BZ {
    background-position: 0 -22px;
  }

  .ca,
  .CA {
    background-position: -16px -22px;
  }

  .cd,
  .CD {
    background-position: -32px -22px;
  }

  .cf,
  .CF {
    background-position: -48px -22px;
  }

  .cg,
  .CG {
    background-position: -64px -22px;
  }

  .ch,
  .CH {
    background-position: -80px -22px;
  }

  .ci,
  .CI {
    background-position: -96px -22px;
  }

  .ck,
  .CK {
    background-position: -112px -22px;
  }

  .cl,
  .CL {
    background-position: -128px -22px;
  }

  .cm,
  .CM {
    background-position: -144px -22px;
  }

  .cn,
  .CN {
    background-position: -160px -22px;
  }

  .co,
  .CO {
    background-position: -176px -22px;
  }

  .cr,
  .CR {
    background-position: -192px -22px;
  }

  .cu,
  .CU {
    background-position: -208px -22px;
  }

  .cv,
  .CV {
    background-position: -224px -22px;
  }

  .cw,
  .CW {
    background-position: -240px -22px;
  }

  .cy,
  .CY {
    background-position: 0 -33px;
  }

  .cz,
  .CZ {
    background-position: -16px -33px;
  }

  .de,
  .DE {
    background-position: -32px -33px;
  }

  .dj,
  .DJ {
    background-position: -48px -33px;
  }

  .dk,
  .DK {
    background-position: -64px -33px;
  }

  .dm,
  .DM {
    background-position: -80px -33px;
  }

  .do,
  .DO {
    background-position: -96px -33px;
  }

  .dz,
  .DZ {
    background-position: -112px -33px;
  }

  .ec,
  .EC {
    background-position: -128px -33px;
  }

  .ee,
  .EE {
    background-position: -144px -33px;
  }

  .eg,
  .EG {
    background-position: -160px -33px;
  }

  .er,
  .ER {
    background-position: -176px -33px;
  }

  .es,
  .ES {
    background-position: -192px -33px;
  }

  .et,
  .ET {
    background-position: -208px -33px;
  }

  .fi,
  .FI {
    background-position: -224px -33px;
  }

  .fj,
  .FJ {
    background-position: -240px -33px;
  }

  .fk,
  .FK {
    background-position: 0 -44px;
  }

  .fm,
  .FM {
    background-position: -16px -44px;
  }

  .fo,
  .FO {
    background-position: -32px -44px;
  }

  .fr,
  .FR,
  .bl,
  .mf {
    background-position: -48px -44px;
  }

  .ga,
  .GA {
    background-position: -64px -44px;
  }

  .gb,
  .GB {
    background-position: -80px -44px;
  }

  .gd,
  .GD {
    background-position: -96px -44px;
  }

  .ge,
  .GE {
    background-position: -112px -44px;
  }

  .gf,
  .GF {
    background-position: -128px -44px;
  }

  .gh,
  .GH {
    background-position: -144px -44px;
  }

  .gi,
  .GI {
    background-position: -160px -44px;
  }

  .gl,
  .GL {
    background-position: -176px -44px;
  }

  .gm,
  .GM {
    background-position: -192px -44px;
  }

  .gn,
  .GN {
    background-position: -208px -44px;
  }

  .gp,
  .GP {
    background-position: -224px -44px;
  }

  .gq,
  .GQ {
    background-position: -240px -44px;
  }

  .gr,
  .GR {
    background-position: 0 -55px;
  }

  .gt,
  .GT {
    background-position: -16px -55px;
  }

  .gu,
  .GU {
    background-position: -32px -55px;
  }

  .gw,
  .GW {
    background-position: -48px -55px;
  }

  .gy,
  .GY {
    background-position: -64px -55px;
  }

  .hk,
  .HK {
    background-position: -80px -55px;
  }

  .hn,
  .HN {
    background-position: -96px -55px;
  }

  .hr,
  .HR {
    background-position: -112px -55px;
  }

  .ht,
  .HT {
    background-position: -128px -55px;
  }

  .hu,
  .HU {
    background-position: -144px -55px;
  }

  .id,
  .ID {
    background-position: -160px -55px;
  }

  .ie,
  .IE {
    background-position: -176px -55px;
  }

  .il,
  .IL {
    background-position: -192px -55px;
  }

  .in,
  .IN {
    background-position: -208px -55px;
  }

  .io,
  .IO {
    background-position: -224px -55px;
  }

  .iq,
  .IQ {
    background-position: -240px -55px;
  }

  .ir,
  .IR {
    background-position: 0 -66px;
  }

  .is,
  .IS {
    background-position: -16px -66px;
  }

  .it,
  .IT {
    background-position: -32px -66px;
  }

  .je,
  .JE {
    background-position: -144px -154px;
  }

  .jm,
  .JM {
    background-position: -48px -66px;
  }

  .jo,
  .JO {
    background-position: -64px -66px;
  }

  .jp,
  .JP {
    background-position: -80px -66px;
  }

  .ke,
  .KE {
    background-position: -96px -66px;
  }

  .kg,
  .KG {
    background-position: -112px -66px;
  }

  .kh,
  .KH {
    background-position: -128px -66px;
  }

  .ki,
  .KI {
    background-position: -144px -66px;
  }

  .xk,
  .XK {
    background-position: -128px -154px;
  }

  .km,
  .KM {
    background-position: -160px -66px;
  }

  .kn,
  .KN {
    background-position: -176px -66px;
  }

  .kp,
  .KP {
    background-position: -192px -66px;
  }

  .kr,
  .KR {
    background-position: -208px -66px;
  }

  .kw,
  .KW {
    background-position: -224px -66px;
  }

  .ky,
  .KY {
    background-position: -240px -66px;
  }

  .kz,
  .KZ {
    background-position: 0 -77px;
  }

  .la,
  .LA {
    background-position: -16px -77px;
  }

  .lb,
  .LB {
    background-position: -32px -77px;
  }

  .lc,
  .LC {
    background-position: -48px -77px;
  }

  .li,
  .LI {
    background-position: -64px -77px;
  }

  .lk,
  .LK {
    background-position: -80px -77px;
  }

  .lr,
  .LR {
    background-position: -96px -77px;
  }

  .ls,
  .LS {
    background-position: -112px -77px;
  }

  .lt,
  .LT {
    background-position: -128px -77px;
  }

  .lu,
  .LU {
    background-position: -144px -77px;
  }

  .lv,
  .LV {
    background-position: -160px -77px;
  }

  .ly,
  .LY {
    background-position: -176px -77px;
  }

  .ma,
  .MA {
    background-position: -192px -77px;
  }

  .mc,
  .MC {
    background-position: -208px -77px;
  }

  .md,
  .MD {
    background-position: -224px -77px;
  }

  .me,
  .ME {
    background-position: -112px -154px;
    height: 12px;
  }

  .mg,
  .MG {
    background-position: 0 -88px;
  }

  .mh,
  .MH {
    background-position: -16px -88px;
  }

  .mk,
  .MK {
    background-position: -32px -88px;
  }

  .ml,
  .ML {
    background-position: -48px -88px;
  }

  .mm,
  .MM {
    background-position: -64px -88px;
  }

  .mn,
  .MN {
    background-position: -80px -88px;
  }

  .mo,
  .MO {
    background-position: -96px -88px;
  }

  .mp,
  .MP {
    background-position: -112px -88px;
  }

  .mq,
  .MQ {
    background-position: -128px -88px;
  }

  .mr,
  .MR {
    background-position: -144px -88px;
  }

  .ms,
  .MS {
    background-position: -160px -88px;
  }

  .mt,
  .MT {
    background-position: -176px -88px;
  }

  .mu,
  .MU {
    background-position: -192px -88px;
  }

  .mv,
  .MV {
    background-position: -208px -88px;
  }

  .mw,
  .MW {
    background-position: -224px -88px;
  }

  .mx,
  .MX {
    background-position: -240px -88px;
  }

  .my,
  .MY {
    background-position: 0 -99px;
  }

  .mz,
  .MZ {
    background-position: -16px -99px;
  }

  .na,
  .NA {
    background-position: -32px -99px;
  }

  .nc,
  .NC {
    background-position: -48px -99px;
  }

  .ne,
  .NE {
    background-position: -64px -99px;
  }

  .nf,
  .NF {
    background-position: -80px -99px;
  }

  .ng,
  .NG {
    background-position: -96px -99px;
  }

  .ni,
  .NI {
    background-position: -112px -99px;
  }

  .nl,
  .NL,
  .bq {
    background-position: -128px -99px;
  }

  .no,
  .NO {
    background-position: -144px -99px;
  }

  .np,
  .NP {
    background-position: -160px -99px;
  }

  .nr,
  .NR {
    background-position: -176px -99px;
  }

  .nu,
  .NU {
    background-position: -192px -99px;
  }

  .nz,
  .NZ {
    background-position: -208px -99px;
  }

  .om,
  .OM {
    background-position: -224px -99px;
  }

  .pa,
  .PA {
    background-position: -240px -99px;
  }

  .pe,
  .PE {
    background-position: 0 -110px;
  }

  .pf,
  .PF {
    background-position: -16px -110px;
  }

  .pg,
  .PG {
    background-position: -32px -110px;
  }

  .ph,
  .PH {
    background-position: -48px -110px;
  }

  .pk,
  .PK {
    background-position: -64px -110px;
  }

  .pl,
  .PL {
    background-position: -80px -110px;
  }

  .pm,
  .PM {
    background-position: -96px -110px;
  }

  .pr,
  .PR {
    background-position: -112px -110px;
  }

  .ps,
  .PS {
    background-position: -128px -110px;
  }

  .pt,
  .PT {
    background-position: -144px -110px;
  }

  .pw,
  .PW {
    background-position: -160px -110px;
  }

  .py,
  .PY {
    background-position: -176px -110px;
  }

  .qa,
  .QA {
    background-position: -192px -110px;
  }

  .re,
  .RE {
    background-position: -208px -110px;
  }

  .ro,
  .RO {
    background-position: -224px -110px;
  }

  .rs,
  .RS {
    background-position: -240px -110px;
  }

  .ru,
  .RU {
    background-position: 0 -121px;
  }

  .rw,
  .RW {
    background-position: -16px -121px;
  }

  .sa,
  .SA {
    background-position: -32px -121px;
  }

  .sb,
  .SB {
    background-position: -48px -121px;
  }

  .sc,
  .SC {
    background-position: -64px -121px;
  }

  .sd,
  .SD {
    background-position: -80px -121px;
  }

  .se,
  .SE {
    background-position: -96px -121px;
  }

  .sg,
  .SG {
    background-position: -112px -121px;
  }

  .sh,
  .SH {
    background-position: -128px -121px;
  }

  .si,
  .SI {
    background-position: -144px -121px;
  }

  .sk,
  .SK {
    background-position: -160px -121px;
  }

  .sl,
  .SL {
    background-position: -176px -121px;
  }

  .sm,
  .SM {
    background-position: -192px -121px;
  }

  .sn,
  .SN {
    background-position: -208px -121px;
  }

  .so,
  .SO {
    background-position: -224px -121px;
  }

  .sr,
  .SR {
    background-position: -240px -121px;
  }

  .ss,
  .SS {
    background-position: 0 -132px;
  }

  .st,
  .ST {
    background-position: -16px -132px;
  }

  .sv,
  .SV {
    background-position: -32px -132px;
  }

  .sx,
  .SX {
    background-position: -48px -132px;
  }

  .sy,
  .SY {
    background-position: -64px -132px;
  }

  .sz,
  .SZ {
    background-position: -80px -132px;
  }

  .tc,
  .TC {
    background-position: -96px -132px;
  }

  .td,
  .TD {
    background-position: -112px -132px;
  }

  .tg,
  .TG {
    background-position: -128px -132px;
  }

  .th,
  .TH {
    background-position: -144px -132px;
  }

  .tj,
  .TJ {
    background-position: -160px -132px;
  }

  .tk,
  .TK {
    background-position: -176px -132px;
  }

  .tl,
  .TL {
    background-position: -192px -132px;
  }

  .tm,
  .TM {
    background-position: -208px -132px;
  }

  .tn,
  .TN {
    background-position: -224px -132px;
  }

  .to,
  .TO {
    background-position: -240px -132px;
  }

  .tr,
  .TR {
    background-position: 0 -143px;
  }

  .tt,
  .TT {
    background-position: -16px -143px;
  }

  .tv,
  .TV {
    background-position: -32px -143px;
  }

  .tw,
  .TW {
    background-position: -48px -143px;
  }

  .tz,
  .TZ {
    background-position: -64px -143px;
  }

  .ua,
  .UA {
    background-position: -80px -143px;
  }

  .ug,
  .UG {
    background-position: -96px -143px;
  }

  .us,
  .US {
    background-position: -112px -143px;
  }

  .uy,
  .UY {
    background-position: -128px -143px;
  }

  .uz,
  .UZ {
    background-position: -144px -143px;
  }

  .va,
  .VA {
    background-position: -160px -143px;
  }

  .vc,
  .VC {
    background-position: -176px -143px;
  }

  .ve,
  .VE {
    background-position: -192px -143px;
  }

  .vg,
  .VG {
    background-position: -208px -143px;
  }

  .vi,
  .VI {
    background-position: -224px -143px;
  }

  .vn,
  .VN {
    background-position: -240px -143px;
  }

  .vu,
  .VU {
    background-position: 0 -154px;
  }

  .wf,
  .WF {
    background-position: -16px -154px;
  }

  .ws,
  .WS {
    background-position: -32px -154px;
  }

  .ye,
  .YE {
    background-position: -48px -154px;
  }

  .za,
  .ZA {
    background-position: -64px -154px;
  }

  .zm,
  .ZM {
    background-position: -80px -154px;
  }

  .zw,
  .ZW {
    background-position: -96px -154px;
  }

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .hide {
    display: none;
  }

  .v-hide {
    visibility: hidden;
  }
}