/* start subscription page input range*/
.subscription-input-range * {
    font-family: var(--font-primary);
}

.subscription-input-range .input-range__label-container {
    color: hsl(var(--muted-foreground));
}

.subscription-input-range .input-range__label {
    display: none;
}

.subscription-input-range .input-range__slider {
    border: 1px solid hsl(var(--background));
    background-color: hsl(var(--foreground));
    height: 16px;
    width: 16px;
    position: relative;
    top: -5px;
}

.subscription-input-range .input-range__track.input-range__track--background {
    background-color: hsl(var(--muted-foreground));
}

.subscription-input-range .input-range__track.input-range__track--active {
    background-color: hsl(var(--foreground));
}

.subscription-input-range.label-visible .input-range__label {
    display: block;
}

.subscription-input-range.label-visible .input-range__slider {
    top: 0;
}

.subscription-input-range.label-visible .input-range__label--value {
    top: -2.25rem;
}

.subscription-input-range.label-visible .input-range__label--min,
.subscription-input-range.label-visible .input-range__label--max {
    top: 1.25rem;
}