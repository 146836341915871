@import "reactflow/dist/style.css";
@import "reactflow/dist/style.css";
@import "reactflow/dist/base.css";

/* Top progress bar */

#nprogress .bar {
  background: hsl(var(--ring));
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px hsl(var(--ring)), 0 0 5px hsl(var(--ring));
}

#nprogress .spinner-icon {
  border-top-color: hsl(var(--ring));
  border-left-color: hsl(var(--ring));
}

/* Top progress bar */

.slick-next:before {
  color: hsl(var(--ring)) !important;
}

.slick-prev:before {
  color: hsl(var(--ring)) !important;
}

.port.target, .port.source{
  width: 40px !important;
  height: 40px !important;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='m16 12l-6 6V6z'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.port.target{
  left: -22px !important;
}
.port.source{
  right: -22px !important;
}

.react-flow__handle.port {
  height: 13px;
  width: 13px;
  border: none !important;
  background-color: hsl(var(--foreground));
}

.react-flow__panel.react-flow__controls * {
  color: hsl(var(--foreground));
  fill: hsl(var(--foreground));
  border-color: hsl(var(--input));
}

.react-flow__panel.react-flow__controls button:last-child {
  border-bottom: none;
}

.react-flow__controls {
  /* box-shadow: 0 0 4px 1px hsl(var(--input)) !important; */
  box-shadow: none !important;
  border: 1px solid hsl(var(--input));
}
.react-flow__controls-button:hover {
  background: hsl(var(--accent)) !important;
}
.react-flow__panel.right {
  display: none;
}